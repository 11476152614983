import './index.css';
import App from './App';
import React from 'react';
import moment from 'moment';
import 'antd/dist/antd.css';
import 'moment/locale/zh-cn';
import ReactDOM from 'react-dom';
// 由于 antd 组件的默认文案是英文，所以需要修改为中文
import zhCN from 'antd/lib/locale/zh_CN';
import reportWebVitals from './reportWebVitals';
import { Router, Route, hashHistory } from 'react-router';

moment.locale('zh-cn');

ReactDOM.render(
    <App />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
