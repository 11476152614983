import React from 'react';
import style from '../styles/banner'
import { Carousel, Button, Row, Col } from 'antd';
import {BrowserRouter as Router, NavLink, Link} from 'react-router-dom';

export default class Banner extends React.Component {
    constructor() {
        super ();
    };

    render () {
        return (
            <Carousel autoplay>
                <div>
                    <div style={style.bg01}>
                        <Link to='/Nopsystem'>
                            <h1 style={style.title}>可源NOP</h1>
                            <p style={style.subTitle}>基于开源框架的新一代网络运维平台</p>
                        </Link>
                        {/*<Button type="primary">产品介绍</Button>*/}
                        {/*<Button type="primary">产品介绍</Button>*/}
                    </div>
                </div>
                <div>
                    <div style={style.bg02}>
                        <Link to='/Bk'>
                            <h1 style={style.title}>蓝鲸SAAS平台</h1>
                            <p style={style.subTitle}>致力于IT运维自动化</p>
                        </Link>
                    </div>
                </div>
                <div>
                    <div style={style.bg03}>
                        <Link to='/Contact'>
                            <h1 style={style.title}>加入我们</h1>
                            <p style={style.subTitle}>可源科技</p>
                        </Link>
                    </div>
                </div>
            </Carousel>
        )
    }
}
