import React from 'react';
import {Row, Col, Button,  Form, Input, Checkbox, Select,  message} from 'antd';
import Record from "../common/record"
import axios from "axios"
import {BASIC} from '../../api/basic'
import {
    WarningOutlined
} from '@ant-design/icons';

const { Option } = Select;

const buttonStyle = {
    width: '100%',
    marginBottom: '21.74px',
    size: 'large',
    backgroundColor: 'rgb(74, 171, 209)'
};

const buttonStyle1 = {
    width: '100%',
    marginBottom: '21.74px',
    size: 'large',
    backgroundColor: 'rgb(67, 67, 67)'
};

const container = {
    padding: '0px 0 40px 0'
};


const title = {
    textAlign: 'left',
    fontSize: '18px'
};

const article = {
    margin: '0 0 10px 0',
    lineHeight: '1.8',
    color: '#666',
    textAlign: 'left',
};

var emailButton = false;

const emailCheck = (value) => {
    let reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
    if(reg.test(value)){
        return false;
    }else{
        return true;
    }
};

const phoneCheck = (val) => {
    if(val==''){
        return false
    }
    let myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
    if (!myreg.test(val)) {
        return false
    } else {
        return true
    }
};


const onFinish = (values) => {
    console.log('Received values of form: ', values);
    if (values.loginPassword !== values.confirmPassword) {
        message.warning('确认密码不一致')
        return
    }

    if (emailCheck(email)) {
        message.warning('邮件格式不对')
        return;
    }
    let form = {
        // 公司名称
        companyName: values.companyName,
        // 公司官网地址
        netAddress: values.companyNetAdress,
        // 公司所属行业
        trade: values.companyType,
        // 公司注册资金
        registeredCapital: values.companyZcMoney,
        // 去年销售额
        sales: values.lastYearMoney,
        // 公司地址
        companyAddress: values.companyAdress,
        // user 联系人名称
        name: values.perName,
        // 公司法人
        legal: values.companyPerson,
        // 公司电话
        cphone:  values.companyPhone,
        // 邮编
        postcode: values.companyYbAdress,
        // 联系方式
        phone: values.perPhone,
        // user 电子邮箱
        email: email,
        // 密码
        password: values.loginPassword,
        // 验证码
        verificationCode: values.emailYzm
}
    axios.post(BASIC.REGISTER.url, form).then(res => {
        console.log(res)
        if (res.data.code === '0000') {
            message.success(res.data.result)
        } else {
            message.error(res.data.result)
        }
    })
};

var email = '';

// const sendEmail = (val) => {
//     emailButton = true
//     console.log(emailButton)
//
//     // axios.post("http://192.168.0.224:8080/verify", {email: email}).then(res => {
//     //     console.log(res)
//     // })
// };

const emailChange = (val) => {
    email = val.target.value
}

export default class ApplicationJoin extends React.Component {
    constructor(props) {
        super(props);
        this.state ={emailButton:false, buttonValue: '发送邮件', td: ''};
        this.sendEmail = () => {
            if (emailCheck(email)) {
                message.warning('请输入正确的邮件格式!')
                return;
            }
            axios.post(BASIC.VERIFY_EMAIL.url, {email: email}).then(res => {
                if (res.data.code === '0000') {
                    message.success('验证码发送成功，请及时查收')
                } else {
                    message.error(res.data.result)
                }
            })
            this.setState({emailButton: true, buttonValue: 60});
            let _this = this
            this.state.td= setInterval(() => {
                _this.setState({buttonValue: --_this.state.buttonValue});
                if (_this.state.buttonValue === 0){
                    clearInterval(_this.state.td)
                    _this.setState({emailButton:false, buttonValue: '发送邮件', td: ''});
                }
            }, 1000)
        }
    };
    render() {
        return (
            <div style={container}>
                <Record assemblyName='申请加入'></Record>
                {/*<div style={{marginTop: '25px'}}></div>*/}
                <Row>
                    <Col span={3}></Col>
                    <Col span={18} style={{textAlign: 'left'}}>
                        <WarningOutlined style={{float: 'left', lineHeight: '54px', textIndent: '13px'}}/>
                        <div style={{color: '#5e7f96', borderColor: '#cfebfe', backgroundColor: '#dff2fe', borderRadius: '5px', padding: '14px 14px 14px 38px', fontSize: '16px'}}>
                            <p style={{margin: '0'}}><b>*</b>&nbsp;标注的栏目必须填写! 我们尊重您的个人隐私，并保证您的个人以及填写的公司信息不被泄漏。</p>
                        </div>
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <br/>
                <br/>
                <Row>
                    <Col span={3}></Col>
                    <Col span={9}>
                        <div>
                            <Form
                                onFinish={onFinish}
                                name="basic"
                                labelCol={{
                                    span: 6,
                                }}
                                initialValues={{ remember: true }}
                            >
                                <Form.Item
                                    label="公司中文名称："
                                    name="companyName"
                                    rules={[{ required: true, message: '请输入公司中文名称' }]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="公司所属行业："
                                    name="companyType"
                                    // rules={[{ required: true, message: '请选择公司所属行业' }]}
                                >
                                    <Select>
                                        <Option value="代理商">代理商</Option>
                                        <Option value="分销商">分销商</Option>
                                        <Option value="提供商">提供商</Option>
                                        <Option value="其他">其他</Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label="公司官方网址："
                                    name="companyNetAdress"
                                    // rules={[{ required: true, message: '请输入公司官方网址' }]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="公司注册资金："
                                    name="companyZcMoney"
                                    // rules={[{ required: true, message: '请输入公司注册资金' }]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="去年销售额："
                                    name="lastYearMoney"
                                    // rules={[{ required: true, message: '请输入去年销售额' }]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="公司地址 ："
                                    name="companyAdress"
                                    // rules={[{ required: true, message: '请输入您的公司地址' }]}
                                >
                                    <Input placeholder="上海市，浦东新区，地址门牌号，公司名称" />
                                </Form.Item>

                                <Form.Item
                                    label="公司法人："
                                    name="companyPerson"
                                    // rules={[{ required: true, message: '请输入公司法人' }]}
                                >
                                    <Input/>
                                </Form.Item>

                                <Form.Item
                                    label="公司电话："
                                    name="companyPhone"
                                    // rules={[{ required: true, message: '请输入公司电话' }]}
                                >
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label="联系人姓名："
                                    name="perName"
                                    // rules={[{ required: true, message: '请输入联系人姓名' }]}
                                >
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label="联系人电话："
                                    name="perPhone"
                                    rules={[{ required: true, message: '请输入联系人电话' }]}
                                >
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    style={{textAlign: 'left'}}
                                    label="联系人邮件："
                                    rules={[{ required: true, message: '请输入联系人邮件' }]}
                                >
                                    <Input onChange ={event => emailChange(event)} style={{width: '70%'}} />
                                    <Button onClick={this.sendEmail} disabled={this.state.emailButton} style={{width: '30%'}}>{this.state.buttonValue}</Button>
                                </Form.Item>
                                <Form.Item
                                    label="验证码："
                                    name="emailYzm"
                                    rules={[{ required: true, message: '请输入验证码' }]}
                                >
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label="登录密码："
                                    name="loginPassword"

                                    rules={[{ required: true, message: '请输入登录密码(论坛可用)' }]}
                                >
                                    <Input.Password placeholder="请输入登录密码(论坛可用)"/>
                                </Form.Item>
                                <Form.Item
                                    label="确认密码："
                                    name="confirmPassword"

                                    rules={[{ required: true, message: '请输入确认密码' }]}
                                >
                                    <Input.Password placeholder="请输入确认密码"/>
                                </Form.Item>
                                <Form.Item
                                    label="公司邮编地址："
                                    name="companyYbAdress"
                                    // rules={[{ required: true, message: '请输入公司邮编地址' }]}
                                >
                                    <Input/>
                                </Form.Item>
                                {/*<Form.Item*/}
                                {/*    label="公司传真："*/}
                                {/*    name="companyCZ"*/}
                                {/*    rules={[{ required: true, message: '请输入公司传真' }]}*/}
                                {/*>*/}
                                {/*    <Input/>*/}
                                {/*</Form.Item>*/}

                                {/*<Form.Item*/}
                                {/*    label="公司简介:"*/}
                                {/*    name="message"*/}
                                {/*>*/}
                                {/*    <Input.TextArea />*/}
                                {/*</Form.Item>*/}

                                <Form.Item >
                                    <Button type="primary" htmlType="submit">
                                        提交申请
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </Col>

                </Row>
            </div>
        )
    }
}
