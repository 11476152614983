import React from 'react';
import { Row, Col } from 'antd';
import Record from "../common/record"
import imgUrl01 from "../../assent/NOP2021/allView.png";

const synopsis = {
    background: '#FFFFFF',
    height: '300px',
    marginTop: '20px',

};
const synopsisB = {
    background: '#F4F4F4',
    height: '100%',
    marginTop: '20px',
};
const carouselStyle = {
    height:'300px',
    float: 'left'
};
const font = {
    textAlign: 'left',
    textIndent: '2em'
}
const fonth1 = {
    fontSize: '40px',
    fontWeight: '900'
}

const fonth12 = {
    fontSize: '30px',
    fontWeight: '700'
}
const pd = {
    padding: '20px'
}

export default class Bk extends React.Component {
    constructor() {
        super();
    };
    render () {
        return (
            <div>
                <Record assemblyName='蓝鲸体系'></Record>
                <Row>
                    <Col span={3}></Col>
                    <Col span={9} style={synopsis}>
                        <img style={carouselStyle} src={imgUrl01}/>
                    </Col>
                    <Col span={9} style={synopsis}>
                        <h1 style={font}>蓝鲸体系介绍</h1>
                        <div style={font}>蓝鲸智云体系由八大平台组成，即管控平台、配置平台、作业平台、数据平台、容器管理平台、AI平台、集成平台和移动平台，为各种云（公有云、私有云、混合云）的用户提供不同场景、不同需求的一站式技术运营解决方案。 蓝鲸智云体系依托企业级 SOA、PaaS 等理念，运用 Docker 等最先进的云技术构建起了全新的运维模式，致力于以“原子服务集成”和“低成本工具构建”的方式落地 DevOps，帮助运维快速实现“基础服务无人值守”及“增值服务”，并进一步通过 DevOps 的落地实现企业更全面和可持续的效率提升。</div>
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <div style={synopsisB}>
                    <Row>
                        <Col span={3}></Col>
                        <Col span={18}>
                            <h1 style={fonth1}>核心平台</h1>
                            <div>蓝鲸管控系统</div>
                        </Col>
                        <Col span={3}></Col>
                    </Row>
                    <Row gutter={84}>
                        <Col span={3}></Col>
                        <Col span={6} style={pd}>
                            <h1 style={fonth12}>管控平台</h1>
                            <div style={font}>蓝鲸管控平台是蓝鲸体系的底层管控系统，是上层运维服务体系与底层 IaaS 的连接器，为上层提供指令、文件、数据的通道，支持直连模式、代理模式以及为达到最优连接指定级联路由的模式。管控平台是典型的两层分布式 C/S 结构，主要包含智能的蓝鲸 Agent、提供各种服务的 Server 以及 zookeeper、redis、MySQL 等周边保障模块。其中蓝鲸 Agent 是部署在业务机器上的程序，每台业务机器仅部署一个蓝鲸 Agent，其他模块部署无具体要求，用户可以单独部署，也可以混合部署。</div>
                        </Col>
                        <Col span={6} style={pd}>
                            <h1 style={fonth12}>配置平台</h1>
                            <div style={font}>蓝鲸配置平台（CC）是一款面向应用的 CMDB，在 ITIL 体系里，配置管理数据库（CMDB）是构建其它流程的基础，配置平台作为面向业务层面的 CMDB,为蓝鲸体系的其它平台提供了各种运维场景的配置数据服务，存储与管理企业 IT 架构中设备的各种配置信息，它与所有服务支持和服务交付流程都紧密相联，支持这些流程的运转、发挥配置信息的价值，同时依赖于相关流程保证数据的准确性。配置平台提供的主要功能有主机管理、业务拓扑、业务管理、资源池管理、自定义属性管理、操作审计等。</div>
                        </Col>
                        <Col span={6} style={pd}>
                            <h1 style={fonth12}>作业平台</h1>
                            <div style={font}>蓝鲸作业平台（Job）是一套底层基于管控之上的基础运维操作平台，并且具备海量的并发处理能力，除了支持脚本执行、文件拉取/分发、定时执行等一系列可实现的基础运维场景以外，还运用流程化的理念很好的将零碎的单个任务组装成一个作业流程。同时，可通过平台提供的 API 实现对任意作业的调用、查看等操作，与其它平台或系统联动，实现调度自动化。作业平台的主要功能有：快速传输文件、web化脚本管理、支持批量高效执行、流程式管理，一切皆“作业”等。</div>
                        </Col>
                        <Col span={3}></Col>
                    </Row>
                    <Row gutter={84}>
                        <Col span={3}></Col>
                        <Col span={6} style={pd}>
                            <h1 style={fonth12}>数据平台</h1>
                            <div style={font}>蓝鲸数据平台是一个专注于运维领域的低门槛大数据平台，提供了数据接入、清洗、计算、存储、查询和分析的全流程自助化大数据服务，运维人员可以通过统一数据接入、可视化计算任务配置、可视化建模、统一查询等功能，快速的构建基于大数据的可视化、智能化运维支撑工具。数据平台的主要功能有：统一数据接入、可视化计算配置管理（Dataflow）、可视化建模（Modelflow）、数据存储查询等。</div>
                        </Col>
                        <Col span={6} style={pd}>
                            <h1 style={fonth12}>容器管理平台</h1>
                            <div style={font}>蓝鲸容器管理平台是一个用于支撑业务容器化及微服务化的平台，是一种 DevOps 实践。蓝鲸容器管理平台提供了持续集成、持续构建、持续部署的具体实现，并在此基础上，构建了分布式配置管理、服务发现、仓库管理（兼容JFrog与docker hub）、安全健康检查、网络配置服务等主要功能。蓝鲸容器管理平台会以 SaaS 服务的形式提供交互，用户只需通过页面点击就能完成业务镜像的构建、发布等操作。</div>
                        </Col>
                        <Col span={6} style={pd}>
                            <h1 style={fonth12}>集成平台</h1>
                            <div style={font}>蓝鲸集成平台是一个开放的平台，又称蓝鲸 PaaS，让用户可以简单、快速地创建、部署和管理应用，他提供了完善的前后台开发框架、服务总线（ESB）、调度引擎、公共组件等模块，帮助用户快速、低成本、免运维地构建支撑工具和运营系统。集成平台为一个应用从创建到部署，再到后续的维护管理提供了完善的自助化和自动化服务，如日志查询、监控告警等，从而使用户可以将全部精力投入到应用的开发之中。集成平台的主要功能有：支持多语言的开发框架/样例、免运维托管、SaaS运营数据可视化、企业服务总线（API Gateway）、可拖拽的前端服务（MagicBox）等。</div>
                        </Col>
                        <Col span={3}></Col>
                    </Row>
                </div>
            </div>
        )
    }
}
