import React from 'react';
import {Menu, Row, Col, Layout} from 'antd';
import Logo from '../../assent/images/keyuan_white.png'
import { MailOutlined, AppstoreOutlined, SettingOutlined } from '@ant-design/icons';
import {BrowserRouter as Router, NavLink, Link} from 'react-router-dom';
import ApplicationConditions from "../pages/applicationConditions";
import ApplicationProcess from "../pages/applicationProcess";

const { SubMenu } = Menu;

const logoStyle = {
    position: 'inherit'
}

const demo = '2'

export default class ComHeader extends React.Component {
    constructor(props) {
        // console.log(props)
        // let dd = props.datas
        // console.log(dd)
        super (props);
    };

    state = {
        current: 'mail',
    };

    handleClickByLogo = e => {
        console.log('click ', e);
        this.setState({ current: e.key });
    };

    render () {
        const { current } = this.state;
        return (
            <div>
                <Row style={{background: '#001529'}}>
                <Col span={8}>
                    <Link to='/'>
                        <img onClick={this.handleClickByLogo} style={{width: '10%'}} src={Logo}></img>
                    </Link>
                </Col>
                <Col span={16}>
                    {/*icon={<SettingOutlined />}*/}
                    <Menu theme={"dark"} onClick={this.handleClick} selectedKeys={[current]} mode="horizontal">
                        <SubMenu key="sub1" title="产品与解决方案">
                            <Menu.Item key="/Nopsystem">
                                <Link to='/Nopsystem'>NOP网络运维平台</Link>
                            </Menu.Item>
                            <Menu.Item key="/Solution">
                                <Link to='/Solution'>NOP解决方案</Link>
                            </Menu.Item>
                            <Menu.Item key="/Bk">
                                <Link to='/Bk'>蓝鲸体系</Link>
                            </Menu.Item>
                            <Menu.Item key="/NopCommunity">
                                <Link to='/NopCommunity'>NOP社区版</Link>
                            </Menu.Item>
                            <Menu.Item key="/Smart">
                                <Link to='/Smart'>S -mart生态市场</Link>
                            </Menu.Item>
                            <Menu.Item key="/Bksolution">
                                <Link to='/Bksolution'>蓝鲸解决方案</Link>
                            </Menu.Item>
                        </SubMenu>
                        <SubMenu key="sub2" title="服务与支持">
                            <Menu.Item key="/Serves/info">
                                <Link to='/Serves/info'>服务内容</Link></Menu.Item>
                            {/*<Menu.Item key="setting:7">技术咨询</Menu.Item>*/}
                            <Menu.Item key="/ConsultingService">
                                <Link to='/ConsultingService'>购买咨询</Link>
                            </Menu.Item>
                        </SubMenu>
                        <SubMenu key="sub3" title="合作伙伴">
                            <Menu.Item key="/Markout">
                                <Link to='/Markout'>合作方式</Link>
                            </Menu.Item>
                            <Menu.Item key="/ApplicationConditions">
                                <Link to='/ApplicationConditions'>申请条件</Link>
                            </Menu.Item>
                            <Menu.Item key="/ApplicationProcess">
                                <Link to='/ApplicationProcess'>申请流程</Link>
                            </Menu.Item>
                            <Menu.Item key="/ApplicationJoin">
                                <Link to='/ApplicationJoin'>申请加入</Link>
                            </Menu.Item>
                        </SubMenu>
                        <SubMenu key="sub4" title="关于可源科技">
                            <Menu.Item key="/CompanyProfile">
                                <Link to='/Company'>公司简介</Link>
                            </Menu.Item>
                            <Menu.Item key="/Certificate">
                                <Link to='/Certificate'>公司资质</Link>
                            </Menu.Item>
                            <Menu.Item key="/TypicalCustomers">
                                <Link to='/TypicalCustomers'>典型客户</Link>
                            </Menu.Item>
                            <Menu.Item key="/Contact">
                                <Link to='/Contact'>联系我们</Link>
                            </Menu.Item>
                        </SubMenu>
                    </Menu>
                </Col>
                <Col span={8}></Col>
            </Row>
            </div>
        )
    }
}
