import React from 'react';
import { Row, Col } from 'antd';
import IP_img from '../../assent/certificate/IP地址管理平台.jpg'
import NOP_img from '../../assent/certificate/NOP.jpg'
import MIDDLEWARE_img from '../../assent/certificate/中间件管理平台.jpg'
import REGISTRATION_img from '../../assent/certificate/作品登记证书.jpg'
import High_tech_certificate_img from '../../assent/certificate/高新技术证书.jpg'
import PERF_img from '../../assent/certificate/性能管理平台.jpg'
import TOPO_img from '../../assent/certificate/拓扑管理平台.jpg'
import DATABASE_img from '../../assent/certificate/数据库代码自动审核平台.jpg'
import DATABASE01_img from '../../assent/certificate/数据库自动化部署平台.jpg'
import LOG_img from '../../assent/certificate/日志智能化处理平台.jpg'
import EVENT_img from '../../assent/certificate/智能告警管理平台.jpg'
import FLOW_img from '../../assent/certificate/流量分析平台.jpg'
import TERMINAL_img from '../../assent/certificate/终端管理平台.jpg'
import AUTO_img from '../../assent/certificate/自动化作业管理平台.jpg'
import BLUEKING_img from '../../assent/certificate/蓝鲸三级合作伙伴证书.jpg'
import PROCESS_img from '../../assent/certificate/资产流程管理平台.jpg'
import ASSETS_img from '../../assent/certificate/资产管理平台.jpg'
import PRODUCT_img from '../../assent/certificate/软件产品证书.jpg'
import PRODUCT01_img from '../../assent/certificate/软件企业证书.jpg'
import CONFIG_img from '../../assent/certificate/配置备份管理平台.jpg'

const titleStyle = {
    textAlign: 'center',
    margin: '30px auto',
    fontWeight: '900',
    fontSize: '30px'
}

const imgStyle = {
    width: '100%',
    height: '600px',
    padding: 'auto 30px'
}

const blockStyle = {

}

export default class Certificate extends React.Component {
    constructor() {
        super();
    };

    render() {
        return (
            <div style={blockStyle}>
                <Row>
                    <Col span={2}>&nbsp;</Col>
                    <Col span={20}>
                        <Row>
                            <h1 style={titleStyle}>高新技术证书</h1>
                        </Row>
                        <Row style={{align: 'center'}}>
                            <Col span={8}>&nbsp;</Col>
                            <Col span={8}><img style={{width: '100%', height: '400px', margin: '30px 0'}} src={High_tech_certificate_img}/></Col>
                            <Col span={8}>&nbsp;</Col>
                        </Row>
                        <Row>
                            <h1 style={titleStyle}>软件产品证书</h1>
                        </Row>
                        <Row style={{align: 'center'}}>
                            <Col span={4}></Col>
                            <Col span={8}><img style={{width: '100%', height: '400px', margin: '30px 0'}} src={PRODUCT_img}/></Col>
                            <Col span={8}><img style={{width: '100%', height: '400px', margin: '30px 0'}} src={PRODUCT01_img}/></Col>
                            <Col span={4}></Col>
                        </Row>
                        <Row>
                            <h1 style={titleStyle}>作品登记证书</h1>
                        </Row>
                        <Row>
                            <Col span={7}></Col>
                            <Col span={10}><img style={{width: '100%', height: '500px', margin: '30px auto'}} src={REGISTRATION_img}/></Col>
                            <Col span={7}></Col>
                        </Row>
                        <Row>
                            <h1 style={titleStyle}>蓝鲸技术合作伙伴</h1>
                        </Row>
                        <Row>
                            <Col span={7}></Col>
                            <Col span={10}><img style={{width: '100%', height: '400px', margin: '30px auto'}} src={BLUEKING_img}/></Col>
                            <Col span={7}></Col>
                        </Row>
                        <Row>
                            <h1 style={titleStyle}>软件著作权登记证书</h1>
                        </Row>
                        <Row gutter={24}>
                            <Col span={6}><img style={imgStyle} src={IP_img}/></Col>
                            <Col span={6}><img style={imgStyle} src={NOP_img}/></Col>
                            <Col span={6}><img style={imgStyle} src={PERF_img}/></Col>
                            <Col span={6}><img style={imgStyle} src={MIDDLEWARE_img}/></Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={6}><img style={imgStyle} src={TOPO_img}/></Col>
                            <Col span={6}><img style={imgStyle} src={DATABASE_img}/></Col>
                            <Col span={6}><img style={imgStyle} src={DATABASE01_img}/></Col>
                            <Col span={6}><img style={imgStyle} src={LOG_img}/></Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={6}><img style={imgStyle} src={EVENT_img}/></Col>
                            <Col span={6}><img style={imgStyle} src={FLOW_img}/></Col>
                            <Col span={6}><img style={imgStyle} src={TERMINAL_img}/></Col>
                            <Col span={6}><img style={imgStyle} src={AUTO_img}/></Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={2}></Col>
                            <Col span={6}><img style={imgStyle} src={PROCESS_img}/></Col>
                            <Col span={1}></Col>
                            <Col span={6}><img style={imgStyle} src={ASSETS_img}/></Col>
                            <Col span={1}></Col>
                            <Col span={6}><img style={imgStyle} src={CONFIG_img}/></Col>
                            <Col span={2}></Col>
                        </Row>
                    </Col>
                    <Col span={2}>&nbsp;</Col>
                </Row>

            </div>
        )
    }
}
