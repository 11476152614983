import React from 'react';
// import Http from "axios"; // 导入axios
import Banner from "../banner";
import Record from "../common/record"
import { Menu, Row, Col, Tabs } from 'antd';
import imgUrl_CMDB from '../../assent/NOP2021/CMDB.jpg'
import imgUrl_JOB from '../../assent/NOP2021/JOB.jpg'
import imgUrl_FLOW from '../../assent/NOP2021/FLOW.jpg'
import imgUrl_EVENT from '../../assent/NOP2021/EVENT.jpg'
import imgUrl_TOOL from '../../assent/NOP2021/TOOL.jpg'
import imgUrl_IP from '../../assent/NOP2021/IP.jpg'
import imgUrl_TOPO from '../../assent/NOP2021/TOPO.png'
import imgUrl_ROLE from '../../assent/NOP2021/ROLE.jpg'
import imgUrl_PER from '../../assent/NOP2021/PER.jpg'
import imgUrl_icon01 from '../../assent/images/icon01.png'
import imgUrl_icon02 from '../../assent/images/icon02.png'
import imgUrl_icon03 from '../../assent/images/icon03.png'
import imgUrl_icon04 from '../../assent/images/icon04.png'
import home from '../../styles/home'
import axios from "axios";

const { TabPane } = Tabs;
const showTabInfo = [
    {
        name: '设备管理',
        content: '通过多种发现方式将发现的网络设备以分组等多个维度进行纳管，由生命周期及性能快照等数据实时监控，维护网络设备',
        imgUrl: imgUrl_CMDB,
    },
    {
        name: '性能管理',
        content: '可从已纳管的网络设备中选择需要查看的实时KPI性能数据，可收藏为私人定制的组每天查看',
        imgUrl: imgUrl_PER,
    },
    {
        name: '事件管理',
        content: '可以从五个维度【系统日志】【健康巡检】【告警监控】【性能数据】【拨测】接受告警信息，供运维人员实时查询解决，解决方式可添加到知识库以备后用',
        imgUrl: imgUrl_EVENT,
    },
    {
        name: '作业调度',
        content: 'NOP系统拥有强大的任务调度引擎，能够自定义将多个系统的作业任务串接成一个流程，轻松实现跨系统的调度自动化。',
        imgUrl: imgUrl_JOB,
    },
    {
        name: '模块工具',
        content: '系统内提供了多个便利化工具【终端查找，正则表达式，WEB终端，ping工具，TraceRoute】，以方便运维人员的实施',
        imgUrl: imgUrl_TOOL,
    },
    {
        name: '拓扑管理',
        content: '对以分组的设备，通过可视化拓扑界面直观的查看网络拓扑结构及告警状态，同时提供自由布局和添加虚拟设备。',
        imgUrl: imgUrl_TOPO,
    },
    {
        name: 'IP管理',
        content: '网络以及IT实施复杂性的增加使网络团队不得不使用自动化IP地址管理工具，我们提供了自动化IP地址管理工具可以让管理员分配子网、分配/追踪/回收IP地址以及提供对网络的可视性',
        imgUrl: imgUrl_IP,
    },
    {
        name: '流量分析',
        content: '分析流入和流出流量，获取产生流量的应用程序排行。 你还可以从每个列出的应用程序中查看主机和会话排行。',
        imgUrl: imgUrl_FLOW,
    },
    {
        name: '权限中心',
        content: '系统可从租户，用户组，角色，用户四个维度区分用户的数据权限，从而做到分域分权',
        imgUrl: imgUrl_ROLE,
    }
];

const advantage = {
    text01: '拥有支撑上万台节点的网络规模的管理经验沉淀；丰富的成功案例，满足不同的网络规模\n' +
        '                                和行业场景',
    text02: '从设备资产到IP地址管理；从端口流量性能到数据流量分析；从设备配置备份到自动化\n' +
        '                                巡检及任务分发；从日志监控告警到端到端拨测监控等等覆盖网络管理域的各项能力',
    text03: '基于主流的开源架构，标准的采集通信协议；提供丰富的标准API调用接口',
    text04: '集成一体化的平台，使各功能模块有序协作完成多样的管理需求；良好的用户体验使高效\n' +
        '                                的日常运营成为可能'
}

export default class Home extends React.Component {
    constructor() {
        super ();
    };

    render () {
        return (
            <div style={{marginTop: '-35px'}}>
                <Record assemblyName='首页'></Record>
                <Banner></Banner>
                <Row style={{marginTop: '40px'}}>
                    <Col span={4}></Col>
                    <Col span={16}>
                        <h1 style={{fontWeight: 'bold'}}>提升运维价值</h1>
                        <p>
                            网络运维管理规模越来越大、数据分析要求越来越高、网络服务如果出了问题，是否能快速定位和恢复？ 可源科技帮助用户精细化运维，预防式运维，智能化运维、保证业务交付的持续稳定高效。
                        </p>
                        <Tabs defaultActiveKey="1" centered>
                            {
                                showTabInfo.map((item, index) => {
                                    return (
                                        <TabPane tab={item.name} key={index}>
                                            <Row>
                                                <Col span={12}>
                                                    <img style={home.tabImages} src={item.imgUrl}/>
                                                </Col>
                                                <Col span={12} style={home.tabText}>
                                                    {item.content}
                                                </Col>
                                            </Row>
                                        </TabPane>
                                    )
                                })
                            }
                        </Tabs>
                        <h1 style={home.table}>品牌优势</h1>
                        <Row>
                            <Col span={12} style={home.table01}>
                                <img src={imgUrl_icon01}/>方案成熟<br/><br/>
                                {advantage.text01}
                            </Col>
                            <Col span={12} style={home.table02}>
                                <img src={imgUrl_icon02}/>覆盖全面<br/><br/>
                                {advantage.text02}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12} style={home.table03}>
                                <img src={imgUrl_icon03}/>开放平台<br/><br/>
                                {advantage.text03}
                            </Col>
                            <Col span={12} style={home.table04}>
                                <img src={imgUrl_icon04}/>高效运营<br/><br/>
                                {advantage.text04}
                            </Col>
                        </Row>
                    </Col>
                    <Col span={4}></Col>
                </Row>
                <Row style={home.bottomCol}>
                    <Col span={12} style={home.bottom1Col}>
                        <b style={{fontSize: '20px'}}>IT运维服务</b><br/>
                        您不仅能够获得技术领先的IT智能化运维平台，更能获得可源科技的IT运维管理技术服务，极大提升IT运维效率。
                    </Col>
                    <Col span={12} style={home.bottom2Col}>
                        <b style={{fontSize: '20px'}}>互惠互利</b><br/>
                        可源科技专注于IT运维管理，积累了丰富的经验。 可源科技诚征全国代理，愿意与您一起互惠互利。
                    </Col>
                </Row>
            </div>
        )
    }

    componentDidMount() {
    }
}
