import React from 'react';
import { Row, Col } from 'antd';
import Record from "../common/record"
import imgUrl01 from "../../assent/NOP2021/bk_s-mart.png";

const synopsis = {
    background: '#FFFFFF',
    height: '300px',
    marginTop: '20px',

};
const synopsisB = {
    background: '#F4F4F4',
    height: '600px',
    marginTop: '20px',
};
const carouselStyle = {
    height:'300px',
    float: 'left'
};
const font = {
    textAlign: 'left',
    textIndent: '2em'
}
const fonth1 = {
    fontSize: '40px',
    fontWeight: '900'
}

const fonth12 = {
    fontSize: '30px',
    fontWeight: '700'
}
const pd = {
    padding: '20px'
}

export default class Smart extends React.Component {
    constructor() {
        super ();
    };
    render () {
        return (
            <div>
                <Record assemblyName='S-mart生态市场'></Record>
                <Row>
                    <Col span={3}></Col>
                    <Col span={9} style={synopsis}>
                        <img style={carouselStyle} src={imgUrl01}/>
                    </Col>
                    <Col span={9} style={synopsis}>
                        <h1 style={font}>企业级应用生态市场</h1>
                        <div style={font}>SaaS是基于蓝鲸集成平台(PaaS)的应用标准化交付与管理统一的前后端开发框架丰富的标准基础组件，其中包含了蓝鲸的基础包(故障自愈,标准运维,流程管理服务,日志检索,蓝鲸监控,节点管理,配置平台,作业平台);其中可源作为主要的合作伙伴之一,也有自己的SaaS场景应用，符合各企业的具体场景做定制开发。</div>
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <div style={synopsisB}>
                    <Row>
                        <Col span={3}></Col>
                        <Col span={18}>
                            <h1 style={fonth1}>通用SAAS</h1>
                            <div>蓝鲸管控系统</div>
                        </Col>
                        <Col span={3}></Col>
                    </Row>
                    <Row gutter={84}>
                        <Col span={3}></Col>
                        <Col span={6} style={pd}>
                            <h1 style={fonth12}>故障自愈</h1>
                            <div style={font}>故障自愈是一款实现服务器故障自动处理的解决方案，提升企业服务可用性和降低故障处理的人力投入。通过自动化处理来节省人力投入，通过预定的恢复流程让恢复过程更可靠，通过并行分析达到更快的故障定位和恢复，最终减少业务损失的风险。</div>
                        </Col>
                        <Col span={6} style={pd}>
                            <h1 style={fonth12}>监控告警</h1>
                            <div style={font}>蓝鲸监控是一款针对主机和互联网应用进行监控的产品，监控服务可用于收集主机资源（系统性能、组件服务、数据库、日志等）的监控指标，探测互联网应用服务的可用性，并对指标进行告警设置。</div>
                        </Col>
                        <Col span={6} style={pd}>
                            <h1 style={fonth12}>标准运维</h1>
                            <div style={font}>标准运维是通过一套成熟稳定的任务调度引擎，把在多系统间的工作整合到一个流程，助力运维实现跨系统调度自动化的 SaaS 应用。</div>
                        </Col>
                        <Col span={3}></Col>
                    </Row>
                    <Row gutter={84}>
                        <Col span={3}></Col>
                        <Col span={6} style={pd}>
                            <h1 style={fonth12}>日志检索</h1>
                            <div style={font}>蓝鲸智云日志检索是为了解决运维场景中查询日志难的问题而推出的一款 SaaS，基于业界主流的全文检索引擎，通过蓝鲸智云的专属 agent 进行日志采集，无需登录各台机器，集中管理所有日志。</div>
                        </Col>
                        <Col span={6} style={pd}>
                            <h1 style={fonth12}>CICDKit</h1>
                            <div style={font}>CICDKit是蓝鲸DevOps平台中为软件开发项目提供持续集成、持续部署支持的SaaS套件。通过页面上简单的信息填写即可以配置出一个适合开发团队特点的持续集成、持续部署流水线，自动化完成软件开发项目过程中包括代码仓库集成、编译构建、单元测试、代码检查、制品归档、镜像构建、自动部署等各个环节，减少开发和运维之间协作的时间损耗，使团队整体更加高效地协同工作、迭代功能、发布产品。</div>
                        </Col>
                        <Col span={6} style={pd}>
                            <h1 style={fonth12}>节点管理</h1>
                            <div style={font}>一款设计用于在浏览器端进行后台服务管理的的应用。目前的版本中, 支持 gse_agent 的安装与升级，满足用户直观简便的在受控主机上部署 Agent。</div>
                        </Col>
                        <Col span={3}></Col>
                    </Row>
                </div>
            </div>
        )
    }
}
