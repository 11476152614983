import React from 'react';
import { Row, Col } from 'antd';
import Record from "../common/record"
import img01 from '../../assent/NOP2021/bks1.jpg'
import img02 from '../../assent/NOP2021/bks2.jpg'
import img03 from '../../assent/NOP2021/bks3.jpg'
import img04 from '../../assent/NOP2021/bks4.jpg'
import img05 from '../../assent/NOP2021/bks5.jpg'
import img06 from '../../assent/NOP2021/bks6.jpg'

const synopsis = {
    background: '#FFFFFF',
    marginTop: '20px',

};
const synopsisB = {
    background: '#F4F4F4',
    height: '100%',
    marginTop: '20px',
};
const carouselStyle = {
    width: '100%',
    float: 'left'
};
const font = {
    textAlign: 'left',
    textIndent: '2em'
}
const fonth1 = {
    fontSize: '40px',
    fontWeight: '900',
    marginTop: '30px'
}

const fonth12 = {
    fontSize: '30px',
    fontWeight: '700'
}
const pd = {
    padding: '20px'
}

export default class BkSolution extends React.Component {
    constructor() {
        super ();
    };
    render () {
        return (
            <div>
                <Record assemblyName='蓝鲸解决方案'></Record>
                <Row>
                    <Col span={3}></Col>
                    <Col span={8} style={synopsis}>
                        <h1 style={{textAlign: 'left',marginLeft: '30px'}}>蓝鲸解决方案</h1>
                        <ul style={{textAlign: 'left'}}>
                            <li>整体解决方案</li>
                            <li>Devops解决方案</li>
                            <li>ITSM解决方案</li>
                            <li>系统变更自动化解决方案</li>
                            <li>应用变更自动化解决方案</li>
                        </ul>
                    </Col>
                    <Col span={10}></Col>
                    <Col span={3}></Col>
                </Row>
                <div style={synopsisB}>
                    <Row>
                        <Col span={3}></Col>
                        <Col span={18}>
                            <h1 style={fonth1}>可源蓝鲸解决方案</h1>
                            <div>基于蓝鲸PAAS平台构建的多场景多功能研发运营一体化解决方案，提供了完善的敏捷开发管理，CI持续集成/测试，CD 持续发布/部署，CO持续技术运营的应用全生命周期管理。</div>
                        </Col>
                        <Col span={3}></Col>
                    </Row>
                    <Row gutter={84}>
                        <Col span={3}></Col>
                        <Col span={6} style={pd}>
                            <h1 style={fonth12}>研发运营一体化</h1>
                            <img style={carouselStyle} src={img01}/>
                            <div style={font}>IaaS 基础架构即服务层提供基础架构的云化管理，提供公有云，私有云和混合云的综合管理方案。 PaaS 平台即服务层提供iPaaS 基础架构的集成和aPaaS业务应用的集成，平台提供统一的服务总线， API Gateway 统一的访问接口，以及简单易用的前后端开发框架。 SaaS软件即服务层提供一体化的运营场景门户功能，提供从应用研发，测试，部署，发布，运维直至运营的整个生命周期的管理。</div>
                        </Col>
                        <Col span={6} style={pd}>
                            <h1 style={fonth12}>Devops解决方案</h1>
                            <img style={carouselStyle} src={img02}/>
                            <div style={font}>可源蓝鲸Devops解决方案借助于蓝鲸平台CI/CD 持续集成/持续发布和CO持续技术运营的平台的中台能力，帮助企业深化应用需求，设计，研发，构建与持续集成，测试部署，测试，发布，运维到运营的全生命周期的管理</div>
                            <ul style={{textAlign: 'left',marginLeft: '-20px'}}>
                                <li>提供Devops能力成熟度评估咨询服务，Devops最佳实践方法论咨询，促进研发和运维从工具，流程到文化的融合。</li>
                                <li>实现CI/CD全程贯通的流水线功能。</li>
                                <li>Devops解决方案覆盖业务的需求，设计，敏捷开发，集成，测试，部署，到发布，运维，运营，持续优化的生命周期。</li>
                            </ul>
                        </Col>
                        <Col span={6} style={pd}>
                            <h1 style={fonth12}>敏捷开发管理</h1>
                            <img style={carouselStyle} src={img03}/>
                            <div style={font}>提供代码仓库和构建包仓库管理工具，支持单元测试，代码质量扫描，确保开发质量；发现的问题可以和敏捷开发工具的ISSUE跟踪集成以及ITSM服务管理流程融合。并支持容器以及构建机。 持续集成/发布流水线管理：</div>
                            <div style={font}>可视化的持续集成、持续部署流水线，支持自动化完成软件开发项目过程中包括代码仓库集成、编译构建、单元测试、代码检查、制品归档、镜像构建、自动部署，应用变更发布自动化等各个环节，减少开发和运维间协作的时间损耗，使团队整体更加高效地协同工作、迭代功能、发布产品。 运维管理：</div>
                            <div style={font}>集成蓝鲸平台强大的标准运维，作业平台，监控管理，配置管理功能，实现持续发布，监控功能，同时能够满足复杂的可扩展的运维场景。</div>
                        </Col>
                        <Col span={3}></Col>
                    </Row>
                    <Row gutter={84}>
                        <Col span={3}></Col>
                        <Col span={6} style={pd}>
                            <h1 style={fonth12}>ITSM服务管理解决方案</h1>
                            <img style={carouselStyle} src={img04}/>
                            <div style={font}>可源ITSM解决方案参考了ITIL 标准/ISO20000服务管理体系以及我们多年国内大企业的落地经验，同时参考了Devops的研发运维一体化实践的需求，使得ITSM服务管理深度融入到企业的应用生命周期的服务管理中，融合研发和运维。</div>
                            <div style={{textAlign: 'left',fontWeight:'700'}}>解决方案:</div>
                            <ul style={{textAlign: 'left',marginLeft: '-20px'}}>
                                <li>ITSM咨询：提供参照ITIL标准, ISO20000服务管理体系以及行业最佳实践的调研，分析，设计等咨询服务。</li>
                                <li>服务流程的咨询设计和落地实施服务：提供流程的咨询和设计，结合企业的实际需求和业界标准及最佳实践，实现服务管理流程的设计到最终电子化落地的服务。涵盖：事件管理，问题管理，请求管理，发布/变更管理，知识管理，服务级别，服务目录管理等客户最密切相关的服务流程</li>
                                <li>ITSM服务管理与Devops过程的敏捷开发管理，持续交付，技术运营等整个应用生命周期集成。</li>
                                <li>ITSM与CMDB配置平台紧密集成，落地基于应用消费场景的可管理的CMDB。</li>
                            </ul>
                        </Col>
                        <Col span={6} style={pd}>
                            <h1 style={fonth12}>系统变更自动化解决方案</h1>
                            <img style={carouselStyle} src={img05}/>
                            <div style={{textAlign: 'left',fontWeight:'700'}}>相关sass：</div>
                            <ul style={{textAlign: 'left',marginLeft: '-20px'}}>
                                <li>物理主机和虚拟机服务器的操作系统自动化安装</li>
                                <li>配置文件管理，管理关键的操作系统，软件，业务应用的关键配置文件的版本，更新，恢复等功能。</li>
                                <li>软件安装自动化，涉及到常用工具软件的自动化安装，应用软件安装，管理软件安装，数据库软件安装，中间件等软件安装。</li>
                                <li>补丁安装，涉及LINUX,UNIX, Windows的补丁软件的测试，批量安装，单独自动安装等功能。</li>
                                <li>数据库变更自动化，涉及到数据库的帐户，表空间，表，视图，配置变更的自动化管理。</li>
                                <li>中间件变更自动化，涉及中间件的配置变更，参数调整等变更自动化管理。</li>
                                <li>健康巡检自动化，涵盖操作系统，数据库，中间件，应用的健康巡检工作，替代大量的人工手工操作。</li>
                                <li>安全审核，提供审核策略，执行安全相关的审核自动化操作并生成审核报告。</li>
                                <li>安全基线管理，满足企业相关的安全基线要求，并更加要求自动进行安全加固。</li>
                            </ul>
                        </Col>
                        <Col span={6} style={pd}>
                            <h1 style={fonth12}>应用变更自动化解决方案</h1>
                            <img style={carouselStyle} src={img06}/>
                            <div style={{textAlign: 'left',fontWeight:'700'}}>相关sass：</div>
                            <ul style={{textAlign: 'left',marginLeft: '-20px'}}>
                                <li>应用配置环境管理：解耦应用和环境，将变更发布的环境解开，生成测试环境，准生产环境，生产环境的配置环境</li>
                                <li>应用包管理：应用版本管理和控制，单独管理构建包仓库。</li>
                                <li>一键发布/回滚：实现应用的一键发布/回滚功能，支持批量和定时发布功能，通过应用发布的流程编排，实现多种复杂应用的自动化发布功能。</li>
                                <li>标准运维：实现流程的编排以及原子的开发管理</li>
                            </ul>
                        </Col>
                        <Col span={3}></Col>
                    </Row>
                </div>
            </div>
        )
    }
}
