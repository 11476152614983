import React from 'react';
import {Row, Col, Button,  Form, Input, Checkbox, Select} from 'antd';
import Record from "../common/record"
import {
    WarningOutlined
} from '@ant-design/icons';

const { Option } = Select;

const buttonStyle = {
    width: '100%',
    marginBottom: '21.74px',
    size: 'large',
    backgroundColor: 'rgb(74, 171, 209)'
};

const buttonStyle1 = {
    width: '100%',
    marginBottom: '21.74px',
    size: 'large',
    backgroundColor: 'rgb(67, 67, 67)'
};

const container = {
    padding: '0px 0 40px 0'
};


const title = {
    textAlign: 'left',
    fontSize: '18px'
};

const article = {
    margin: '0 0 10px 0',
    lineHeight: '1.8',
    color: '#666',
    textAlign: 'left',
};

export default class ConsultingService extends React.Component {
    constructor() {
        super();
    };

    render() {
        return (
            <div style={container}>
                <Record assemblyName='购买咨询'></Record>
                <Row>
                    <Col span={6}></Col>
                    <Col span={12}>
                        <div>
                            <h3 style={{textAlign: 'left', margin: '0px 0 20px 0', fontSize: '24px'}}>请您认真填写以下内容！</h3>
                            <Form
                                name="basic"
                                initialValues={{ remember: true }}
                            >
                                <Form.Item
                                    label="您的账号 （必填）："
                                    name="account"
                                    rules={[{ required: true, message: '请输入您的账号' }]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="您的密码 （必填）："
                                    name="password"
                                    rules={[{ required: true, message: '请输入您的密码' }]}
                                >
                                    <Input.Password />
                                </Form.Item>

                                <Form.Item
                                    label="确认密码 （必填）："
                                    name="password"
                                    rules={[{ required: true, message: '请确认您的密码' }]}
                                >
                                    <Input.Password />
                                </Form.Item>

                                <Form.Item
                                    label="您的名字 （必填）："
                                    name="username"
                                    rules={[{ required: true, message: '请输入您的名字' }]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="您的手机 （必填）："
                                    name="phone"
                                    rules={[{ required: true, message: '请输入您的手机号' }]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="您的邮箱 （必填）："
                                    name="email"
                                    rules={[{ required: true, message: '请输入您的邮箱' }]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="公司名称 （必填）"
                                    name="company"
                                    rules={[{ required: true, message: '请输入您的公司名称' }]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="您的职位 （必填）："
                                    name="position"
                                    rules={[{ required: true, message: '请选择您的职位' }]}
                                >
                                    <Select>
                                        <Option value="技术支持人员">技术支持人员</Option>
                                        <Option value="系统管理员/网络管理员">系统管理员/网络管理员</Option>
                                        <Option value="电子工程师/技术员">电子工程师/技术员</Option>
                                        <Option value="营运经理/主管">营运经理/主管</Option>
                                        <Option value="网络工程师">网络工程师</Option>
                                        <Option value="网络安全工程师">网络安全工程师</Option>
                                        <Option value="其他">其他</Option>
                                    </Select>
                                </Form.Item>


                                <Form.Item
                                    label="公司地址 （必填）："
                                    name="companyAdress"
                                    rules={[{ required: true, message: '请输入您的公司地址' }]}
                                >
                                    <Input placeholder="上海市，浦东新区，地址门牌号，公司名称" />
                                </Form.Item>

                                <Form.Item
                                    label="网络规模 （必填）："
                                    name="networkSize"
                                    rules={[{ required: true, message: '请选择您的网络规模' }]}
                                >
                                    <Select>
                                        <Option value="50-100台">50-100台</Option>
                                        <Option value="101-200台">101-200台</Option>
                                        <Option value="201-500台">201-500台</Option>
                                        <Option value="500台以上">500台以上</Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label="购买用途 （必填）："
                                    name="purpose"
                                    rules={[{ required: true, message: '请选择您的购买用途' }]}
                                >
                                    <Select>
                                        <Option value="网络管理">网络管理</Option>
                                        <Option value="APM">APM</Option>
                                        <Option value="自动化配置">自动化配置</Option>
                                        <Option value="日志大数据">日志大数据</Option>
                                        <Option value="其他用途">其他用途</Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label="补充留言："
                                    name="message"
                                >
                                    <Input.TextArea />
                                </Form.Item>

                                <Form.Item >
                                    <Button type="primary" htmlType="submit">
                                        提交
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </Col>
                    <Col span={6}></Col>
                </Row>
            </div>
        )
    }
}
