import Home from "../pages/home";
import CompanyProfile from "../pages/companyProfile";
import Contact from "../pages/contact";
import ServiceContent from "../pages/serviceContent";
import ApplicationConditions from "../pages/applicationConditions";
import ApplicationProcess from "../pages/applicationProcess";
import ApplicationJoin from "../pages/applicationJoin";
import ConsultingService from "../pages/consultingService";
import Solution from "../pages/solution";
import Smart from "../pages/smart";
import Bk from "../pages/bk";
import Bksolution from "../pages/bksolution";
import Nopsystem from "../pages/nopsystem";
import Markout from "../pages/markout";
import Company from "../pages/company";
import customers from "../pages/customers";
import NopCommunity from "../pages/nopCommunity";
import Certificate from "../pages/certificate";

const routerList = [
    {
        path: '/',
        component: Home,
        auth: true
    },
    {
        path: '/CompanyProfile',
        component: CompanyProfile,
        auth: true
    },
    {
        path: '/TypicalCustomers',
        component: customers,
        auth: true
    },
    {
        path: '/Company',
        component: Company,
        auth: true
    },
    {
        path: '/Serves/info',
        component: ServiceContent,
        auth: true
    },
    {
        path: '/Contact',
        component: Contact,
        auth: true
    },
    {
        path: '/Markout',
        component: Markout,
        auth: true
    },
    {
        path: '/ApplicationConditions',
        component: ApplicationConditions,
        auth: true
    },
    {
        path: '/ApplicationProcess',
        component: ApplicationProcess,
        auth: true
    },
    {
        path: '/ApplicationJoin',
        component: ApplicationJoin,
        auth: true
    },
    {
        path: '/ConsultingService',
        component: ApplicationJoin,
        auth: true
    },
    {
        path: '/Nopsystem',
        component: Nopsystem,
        auth: true
    },
    {
        path: '/Solution',
        component: Solution,
        auth: true
    },
    {
        path: '/Bk',
        component: Bk,
        auth: true
    },
    {
        path: '/Smart',
        component: Smart,
        auth: true
    },
    {
        path: '/Bksolution',
        component: Bksolution,
        auth: true
    },
    {
        path: '/NopCommunity',
        component: NopCommunity,
        auth: true
    },
    {
        path: '/Certificate',
        component: Certificate,
        auth: true
    }
]
export default routerList;
