import React from 'react';
import logo from '../../assent/images/keyuan_white.png'

export default class CompanyProfile extends React.Component {
    constructor() {
        super ();
    };

    render () {
        return (
            <div style={{background: 'red', width: '100%', height: '800px'}}>
                CompanyProfile
                <img src={logo} />
            </div>
        )
    }
}
