import React from 'react';
import axios from "axios"
import {BASIC} from '../../api/basic'

export default class Record extends React.Component {
    constructor(props) {
        super (props);
    };
    componentDidMount() {
        setTimeout(() => {
            let returnCitySN = JSON.parse(localStorage.getItem('postData'))
            console.log(returnCitySN)
            axios.post(BASIC.VIEWER.url, {
                module: this.props.assemblyName,
                cid: returnCitySN.cid,
                cip: returnCitySN.cip,
                area: returnCitySN.area,
                webOS:navigator.platform
            }).then(res => {

            }).catch(e =>{
                console.log(e)
            })
        }, 500)
    };
    render() {
        return ''
    }
}
