import React from 'react';
import {Row, Col, Button} from 'antd';
import Record from "../common/record"
const buttonStyle = {
    width: '100%',
    marginBottom: '21.74px',
    size: 'large',
    backgroundColor: 'rgb(74, 171, 209)'
};

const buttonStyle1 = {
    width: '100%',
    marginBottom: '21.74px',
    size: 'large',
    backgroundColor: 'rgb(67, 67, 67)'
};

const container = {
    padding: '0px 0 40px 0'
};

const title = {
    textAlign: 'left',
    fontSize: '18px'
};

const article = {
    margin: '0 0 10px 0',
    lineHeight: '1.8',
    color: '#666',
    textAlign: 'left',
};

export default class ServiceContent extends React.Component {
    constructor() {
        super();
    };

    render() {
        return (
            <div style={container}>
                <Record assemblyName='服务内容'></Record>
                {/*<div style={{marginTop: '25px'}}></div>*/}
                <Row>
                    <Col span={3}></Col>
                    <Col span={12}>
                        <div>
                            <h4 style={title}>蓝鲸Devops平台咨询和开发实施服务</h4>
                            <p style={article}>Devops（研发运营一体化）指在IT软件及相关服务的研发及交付过程中，将应用的需求、开发、测试、部署和运营统一起来，基于整个组织的协作和应用架构的优化，实现敏捷开发、持续交付和应用运营的生命周期全流程无缝集成。帮助企业提升IT效能，在保证稳定的同时，快速交付高质量的软件及服务，灵活应对快速变化的业务需求和市场环境。</p>
                            <ul style={article}>
                                <li>Devops 研发运营一体化能力成熟度咨询服务，参照行业标准和最佳实践以及成熟度模型，提供能力成熟度分析，一体化设计以及蓝鲸Devops平台落地咨询服务。</li>
                                <li>CMDB配置管理在ITSM和Devops环境下的架构，消费场景咨询以及面向应用生命周期CMDB建设的落地开发实施服务。</li>
                                <li>Devops平台PAAS原子开发服务，高效集成企业的应用到蓝鲸Devops平台。</li>
                                <li>运营场景SAAS开发服务，提供服务器，数据库，存储，网络，应用的自动化运维场景解决方案。</li>
                            </ul>
                            <br></br>
                            <h4 style={title}>可源NOP自主管理平台的实施与客户化服务</h4>
                            <p style={article}>可源自有系统和网络监控平台NOP(网管运维平台)是结合多个大中型项目经验和吸收了国内外商业和开源产品的优点的基础上，打造的一款稳定，高效，功能齐全，内置支持多租户，架构灵活的平台软件；基于该成熟产品为客户快速实施和部署面向全网的新一代的IT监控和运维管理平台，并可以针对客户特有需求进行快速的客户化开发。</p>
                            <ul style={article}>
                                <li>网络运维平台的咨询服务，参照ITIL以及Devops标准，提供管理需求分析，成熟度分析和设计服务。</li>
                                <li>NOP平台的客户化定制以满足客户的网络监控管理，自动化配置管理。</li>
                            </ul>
                            <br></br>
                            <h4 style={title}>ITSM服务管理咨询和开发实施服务</h4>
                            <p style={article}>ITSM (IT Service Management，IT服务管理 ) 起源于 ITIL，它是一套帮助企业对IT服务的战略规划、设计、实施和运营，转换和持续改进进行有效管理的标准化方法。它结合了高质量服务不可缺少的流程、人员和产品，合作伙伴/供应商等四大要素,同时集成Devops敏捷开发，持续交付和应用运营过程，为企业提供完整的标准化，自动化，数字化运营，智能化运营提供保障。</p>
                            <ul style={article}>
                                <li>ITSM服务管理成熟度评估和服务体系，服务流程，服务管理落地的设计服务。</li>
                                <li>ITSM服务管理流程和管理标准化的落地开发实施服务，使之更加符合企业的最佳实践。</li>
                                <li>持续化改进与优化服务，参考客户反馈和新的需求，持续改进和优化服务管理流程。</li>
                                <li>CMDB和Devops敏捷开发，持续交付，持续运营的集成服务。</li>
                            </ul>
                        </div>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={5}>
                        <Button style={buttonStyle} type="primary">技术咨询</Button>
                        <Button style={buttonStyle1} type="primary">购买咨询</Button>
                    </Col>
                    <Col span={3}></Col>
                </Row>
            </div>
    )
    }
    }
