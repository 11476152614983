import React from 'react';
import {Row, Col, Collapse } from 'antd';
import Record from "../common/record"
const { Panel } = Collapse;

const container = {
    padding: '0px 0 40px 0',
    textAlign: 'left'
};

const text = '\t\t\tGeneInfo 是基于大数据和开源架构，基于HTML5展现框架的分布式网络，系统和应用的综合监控管理平台(NMS+APM+CM)，是一个可以进行高度客户化的开放系统。\n'

export default class MarkOut extends React.Component {
    constructor() {
        super();
    };

    render() {
        return (
            <div style={container}>
                <Record assemblyName='合作方式'></Record>
                <Row>
                    <Col span={3}></Col>
                    <Col span={18}>
                        <div className="section full-width">
                            <div className="container" style={{width: '1170px', margin: 'auto', color: '#666',height: '500px'}}>
                                <div className="row">
                                    <p>可源科技软件诚征全国代理，愿意与您一起争取更多的市场份额，为我们庞大的用户群提供本地化的服务，我们的资源与技术，是您成功的保障，期待您的加入！</p>
                                    <h4>条件</h4>
                                    <ol>
                                        <li className="first">有固定并深入的企业用户群及具有优秀销售业绩的合作伙伴，愿意以可源科技产品为首选产品；</li>
                                        <li>能够主动的在行业及企业中推广可源科技的服务理念和产品；</li>
                                        <li>承诺一定的年进货量；</li>
                                        <li>对可源科技公司系列产品均有很好的掌握，能为用户提供全面的安全方案，和完善的售后服务；</li>
                                        <li className="last">有2名以上的专职销售人员，并有1名以上的技术人员参加并通过可源科技系列产品的认证及售后培训考试.</li>
                                    </ol>
                                    <h4>权益</h4>
                                    <ol>
                                        <li className="first">享受一定的产品折扣和奖励；</li>
                                        <li>可有2个名额免费参加可源科技的认证培训；</li>
                                        <li>在向可源科技公司申报项目后，可源科技将在项目中给予直接的销售和技术支持；</li>
                                        <li>可以得到可源科技公司完整的网络安全解决方案及各产品的技术细节；</li>
                                        <li className="last">可源科技将指定专门的技术支持人员对其进行技术响应；</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={3}></Col>
                </Row>
            </div>
        )
    }
}
