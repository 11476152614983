import React from 'react';
import {Row, Col, Button} from 'antd';
import Record from "../common/record"
const buttonStyle = {
    width: '100%',
    marginBottom: '21.74px',
    size: 'large',
    backgroundColor: 'rgb(74, 171, 209)'
};

const buttonStyle1 = {
    width: '100%',
    marginBottom: '21.74px',
    size: 'large',
    backgroundColor: 'rgb(103, 208, 108)'
};

const buttonStyle2 = {
    width: '100%',
    marginBottom: '21.74px',
    size: 'large',
    backgroundColor: 'rgb(67, 67, 67)'
};

const container = {
    padding: '0px 0 40px 0',
};

const title = {
    textAlign: 'left',
    fontSize: '18px'
};

const article = {
    margin: '0 0 10px 0',
    lineHeight: '1.8',
    color: '#666',
    textAlign: 'left',
};

export default class Contact extends React.Component {
    constructor() {
        super();
    };

    render() {
        return (
            <div style={container}>
                <Record assemblyName='联系我们'></Record>
                {/*<div style={{marginTop: '50px'}}></div>*/}
                <Row>
                    <Col span={9}></Col>
                    <Col span={6}>
                        <div style={{textAlign: 'left', height:'500px'}}>
                            <div className="wpb_wrapper">
                                <h2>上海可源科技：</h2>
                                <p>
                                    上海市浦东新区森兰.美奂大厦北楼C座1206室<br/>
                                    电话：13801696857<br/>
                                    邮编：201206<br/>
                                    邮箱：joseph.li@ensource.cn
                                    </p>
                            </div>
                        </div>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={5}>
                        <Button style={buttonStyle} type="primary">技术咨询</Button>
                        <Button style={buttonStyle1} type="primary">购买咨询</Button>
                        <Button style={buttonStyle2} type="primary">代理商申请</Button>
                    </Col>
                    <Col span={3}></Col>
                </Row>
            </div>
        )
    }
}
