import imgUrl01 from "../assent/images/bg01.jpg";
import imgUrl02 from "../assent/images/bg02.jpg";
import imgUrl03 from "../assent/images/bg03.jpg";

export default {
    title: {
        color: '#ffffff',
        position: 'relative',
        fontSize: '80px',
        top: '0px',
        left: '20px',
        padding: '100px'
    },

    subTitle: {
        color: '#ffffff',
        position: 'relative',
        fontSize: '25px',
        top: '-250px',
        left: '25px',
        padding: '100px'
    },

    bg01: {
        background: 'url("' + imgUrl03 + '") no-repeat',
        backgroundSize: 'cover',
        height: '500px',
        width: '100%',
        position: 'relative',
        textAlign: 'left'
    },

    bg02: {
        background: 'url("' + imgUrl02 + '") no-repeat',
        backgroundSize: 'cover',
        height: '500px',
        width: '100%',
        position: 'relative'
    },

    bg03: {
        background: 'url("' + imgUrl01 + '") no-repeat',
        backgroundSize: 'cover',
        height: '500px',
        width: '100%',
        position: 'relative',
        textAlign: 'right'
    },
}
