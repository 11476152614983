import './App.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Layout } from 'antd';
import routerList from "./components/router";
import ComHeader from './components/common/header';
import ComFooter from './components/common/footer';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

const currentHeight = document.body.clientHeight || window.screen.height || document.body.scrollHeight

const heightStyle = {
    height: currentHeight,
    paddingTop: '100px'
}

const {Header, Content, Footer} = Layout


function App() {
  return (
      <div className="App">
          <Router>
              <Layout>
                  <Header style={{position: 'fixed', height: '65px', width: '100%', zIndex: '1000'}}>
                      <ComHeader></ComHeader>
                  </Header>
                  <Content style={{paddingTop: '100px', backgroundColor: '#fff'}}>
                      <Switch>
                          {
                              routerList.map((item, key) => { // 根据路由表配置遍历生成路由
                                  return (
                                      <Route exact key={key} path={item.path} component={item.component}></Route>
                                  )
                              })
                          }
                      </Switch>
                  </Content>
                  <div style={{height: '200px', backgroundColor: '#FFFFFF'}}>&nbsp;</div>
                  <ComFooter></ComFooter>
              </Layout>
          </Router>
      </div>
  );
}
export default App;
