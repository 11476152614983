import React from 'react';
import {Row, Col, Collapse } from 'antd';
import Record from "../common/record"
const { Panel } = Collapse;

const container = {
    padding: '0px 0 40px 0',
    textAlign: 'left'
};

const text = '\t\t\tGeneInfo 是基于大数据和开源架构，基于HTML5展现框架的分布式网络，系统和应用的综合监控管理平台(NMS+APM+CM)，是一个可以进行高度客户化的开放系统。\n'

export default class applicationProcess extends React.Component {
    constructor() {
        super();
    };

    render() {
        return (
            <div style={container}>
                <Record assemblyName='申请流程'></Record>
                <Row>
                    <Col span={3}></Col>
                    <Col span={18}>
                        <div className="section full-width">
                            <div className="container" style={{width: '100%',  color: '#666', height: '500px'}}>
                                <div className="row">
                                    <ul>
                                        <li className="first">了解和认同可源科技IT运维平台代理政策和价格体系。</li>
                                        <li>了解并掌握可源科技IT运维平台。
                                        </li>
                                        <li>在线提交代理商申请表，同时提供代理商需要具备的条件中列出的所需相关文件资料。
                                        </li>
                                        <li>在认同代理政策和价格体系的基础上，与公司签署正式的代理协议及其他相关文件。</li>
                                        <li>可源科技公司以电子邮件方式向代理发出可源科技IT运维平台的帐号和密码，同时向代理发放《代理授权证书》。</li>
                                        <li className="last">公司将为代理提供销售手册及人员销售培训。</li>
                                    </ul>
                                    <p>
                                        <b>如果您已经了解了申请流程，请联系我们</b>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={3}></Col>
                </Row>

            </div>
        )
    }
}
