import React from 'react';
import {Row, Col, Collapse } from 'antd';
import Record from "../common/record"
const { Panel } = Collapse;

const container = {
    padding: '0px 0 40px 0',
    textAlign: 'left'
};

const text = '\t\t\tGeneInfo 是基于大数据和开源架构，基于HTML5展现框架的分布式网络，系统和应用的综合监控管理平台(NMS+APM+CM)，是一个可以进行高度客户化的开放系统。\n'

export default class ApplicationConditions extends React.Component {
    constructor() {
        super();
    };

    render() {
        return (
            <div style={container}>
                <Record assemblyName='申请条件'></Record>
                <Row>
                    <Col span={3}></Col>
                    <Col span={18}>
                        <div className="section full-width">
                            <div className="container" style={{width: '100%',  color: '#666', height:'500px'}}>
                                <div className="row">
                                    <h3 style={{fontWeight: '700', fontSize: '24px'}}>
                                        <b>如果您满足如下申请条件，可以查看<a
                                            href="shenqingliucheng.jsp">申请流程</a>。
                                        </b>
                                    </h3>
                                    <ul>
                                        <li>具有法人资格的合法公司；</li>
                                        <li>从事计算机软件/硬件产品销售的分销商或者经销商；</li>
                                        <li>同意并接受可源的产品使用协议，遵守可源的渠道政策；</li>
                                        <li>从技术角度了解和熟悉可源的产品服务概念和运作流程；</li>
                                        <li>具备诚信度、业务持续发展能力并与某个行业或某地区各行业客户有着良好的业务沟通渠道；</li>
                                        <li>具备持续的业务开展能力，始终和可源保持业务联系，具备较强的业务处理素质，以提供于客户直接、准确的业务咨询服务和业务受理能力。</li>
                                    </ul>
                                    <h4>注意：</h4>
                                    <p>
                                        申请所需要提交的营业执照复印件、法人代表身份证复印件、税务登记复印件，请以邮件附件的形式，Email到这个邮箱：<a
                                        href="mailto:"></a>.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={3}></Col>
                </Row>
            </div>
        )
    }
}
