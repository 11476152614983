import React from 'react';
import { Row, Col } from 'antd';
import {HomeTwoTone, MailTwoTone} from '@ant-design/icons';
import axios from "axios"
import {BASIC} from '../../api/basic'

const h3 = {
    color: '#9c9c9c',
    fontWeight: 'bold'
}


export default class footer extends React.Component {
    constructor() {
        super ();
        let count;
    };

    render () {
        return (
            <div style={{height: '200px', color: 'white', position: 'fixed', bottom: '0', left: '0', right: '0'}}>
                <Row style={{background: '#222222', height: '150px', color: '#9c9c9c', padding: '20px', textAlign: 'left'}}>
                    <Col span={5}></Col>
                    <Col span={5}>
                        <h3 style={h3}>解决方案</h3>
                        蓝鲸智云管控系统<br/>
                        可源网络监控管理平台
                    </Col>
                    <Col span={5}>
                        <h3 style={h3}>联系方式</h3>
                        <HomeTwoTone style={{fontSize: '20px'}} twoToneColor='#ff6666'></HomeTwoTone>&nbsp;&nbsp;&nbsp;上海市浦东新区森兰.美奂大厦北楼C座1206室<br/>
                        <MailTwoTone style={{fontSize: '20px'}} twoToneColor='#ff6666'></MailTwoTone>&nbsp;&nbsp;&nbsp;joseph.li@ensource.cn
                    </Col>
                    <Col span={5}>
                        <h3 style={h3}>关于可源科技</h3>
                        关注可源科技，获取最新网络管理案例、产品使用教程。
                    </Col>
                    <Col span={4}></Col>
                </Row>
                <Row style={{height: '50px', background: '#000', lineHeight: '50px'}}>
                    <Col span={8}>&nbsp;</Col>
                    <Col span={8}>
                        © Copyrights 可源科技 2017 - 2017. 版权所有.&nbsp;&nbsp;&nbsp;
                        <a href="https://beian.miit.gov.cn">沪ICP备17023761号-1</a>
                    </Col>
                    {/*<Col span={8}>&nbsp;访问数:&nbsp;{this.count}</Col>*/}
                </Row>
            </div>
        )
    };

    componentWillMount(){
        let _this = this
        axios.get(BASIC.QUERY_COUNTS.url).then(res => {
            _this.count = res.data
            _this.forceUpdate()
            console.warn(_this.count)
        })
    }
}
