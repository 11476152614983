import {IP_USING} from '../utils/https'
const DEFAULT_URL = IP_USING

export const BASIC = {
    VIEWER: {
        type: 'POST',
        describe: '新增浏览数据',
        url: DEFAULT_URL + '/viewer'
    },
    QUERY_VIEWER: {
        type: 'GET',
        describe: '分页查询浏览记录',
        url: DEFAULT_URL + '/queryViewer'
    },
    REGISTER: {
        type: 'POST',
        describe: '注册用户',
        url: DEFAULT_URL + '/register'
    },
    QUERY_COUNTS: {
        type: 'GET',
        describe: '获取访问总数',
        url: DEFAULT_URL + '/counts'
    },
    VERIFY_EMAIL: {
        type: 'POST',
        describe: '邮箱验证',
        url: DEFAULT_URL + '/verify'
    },
    QUERY_USER: {
        type: 'GET',
        describe: '分页查询用户',
        url: DEFAULT_URL + '/queryUser'
    }
}
