import React from 'react';
import {Row, Col, Collapse } from 'antd';
import imgbaiyun from "../../assent/businessLogo/baiyun.jfif";
import imgdongfeng from "../../assent/businessLogo/dongfeng.jfif";
import imghuaxia from "../../assent/businessLogo/huaxia.png";
import imghuayu from "../../assent/businessLogo/huayu.jfif";
import imglanjing from "../../assent/businessLogo/lanjing.jpg";
import imglianhe from "../../assent/businessLogo/lianhe.jpg";
import imgnanwang from "../../assent/businessLogo/nanwang.jfif";
import imgsgm from "../../assent/businessLogo/SGM.jfif";
import imgtianan from "../../assent/businessLogo/tianan.jpg";
import imgxinhua from "../../assent/businessLogo/xinhua.jfif";
import imgyiqi from "../../assent/businessLogo/一汽丰田.jpg";
import imgyidong from "../../assent/businessLogo/中国移动.png";
import imgbaojianhui from "../../assent/businessLogo/银保监会.png";
import imghunan from "../../assent/businessLogo/湖南农信.jpg";
import imgehi from "../../assent/businessLogo/ehi.png";
import Record from "../common/record"
const { Panel } = Collapse;


const container = {
    padding: '0px 0 40px 0',
    textAlign: 'left'
};

const text = '\t\t\tGeneInfo 是基于大数据和开源架构，基于HTML5展现框架的分布式网络，系统和应用的综合监控管理平台(NMS+APM+CM)，是一个可以进行高度客户化的开放系统。\n'

export default class Customers extends React.Component {
    constructor() {
        super();
    };

    render() {
        return (
            <div style={container}>
                <Record assemblyName='典型客户'></Record>
                <div className="section full-width">
                    <div className="container" style={{width: '1170px', margin: 'auto', color: '#666', height: '500px'}}>
                        <Row>
                            {/*<Col span={2}></Col>*/}
                            <Col span={24}>
                                <Row style={{textAlign: 'center', margin: '10px 0px', fontSize: '20px', color: '#001529', marginLeft: '20px'}}>
                                    <b>国内典型客户</b>
                                </Row>
                                <Row>
                                    <Col span={5}>
                                        <div style={{height: '200px'}}>
                                            <img style={{width: '180px', height: '160px'}} src={imgbaiyun} alt=""/>
                                        </div>
                                        <div style={{height: '200px'}}>
                                            <img style={{width: '180px', height: '160px'}} src={imgdongfeng} alt=""/>
                                        </div>
                                        <div style={{height: '200px'}}>
                                            <img style={{width: '180px', height: '160px'}} src={imghuaxia} alt=""/>
                                        </div>
                                    </Col>
                                    <Col span={5}>
                                        <div style={{height: '200px'}}>
                                        <img style={{width: '180px', height: '160px'}} src={imglianhe} alt=""/>
                                        </div>
                                        <div style={{height: '200px'}}>
                                        <img style={{width: '180px', height: '140px'}} src={imghuayu} alt=""/>
                                        </div>
                                        <div style={{height: '200px'}}>
                                        <img style={{width: '180px', height: '160px'}} src={imglanjing} alt=""/>
                                        </div>
                                    </Col>
                                    <Col span={5}>
                                        <div style={{height: '200px'}}>
                                        <img style={{width: '180px', height: '160px'}} src={imgnanwang} alt=""/>
                                        </div>
                                        <div style={{height: '200px'}}>
                                        <img style={{width: '180px', height: '200px'}} src={imgsgm} alt=""/>
                                        </div>
                                        <div style={{height: '200px'}}>
                                        <img style={{width: '180px', height: '160px'}} src={imgtianan} alt=""/>
                                        </div>
                                    </Col>
                                    <Col span={5}>
                                        <div style={{height: '200px'}}>
                                        <img style={{width: '180px', height: '160px'}} src={imgyiqi} alt=""/>
                                        </div>
                                        <div style={{height: '200px', paddingTop: '50px'}}>
                                        <img style={{width: '180px', height: '70px'}} src={imgyidong} alt=""/>
                                        </div>
                                        <div style={{height: '200px'}}>
                                        <img style={{width: '180px', height: '160px'}} src={imgxinhua} alt=""/>
                                        </div>
                                    </Col>
                                    <Col span={4}>
                                        <div style={{height: '200px', paddingTop: '50px'}}>
                                        <img style={{width: '180px', height: '60px'}} src={imgbaojianhui} alt=""/>
                                        </div>
                                        <div style={{height: '200px', paddingTop: '10px'}}>
                                        <img style={{width: '240px', height: '160px'}} src={imghunan} alt=""/>
                                        </div>
                                        <div style={{height: '200px', paddingTop: '50px'}}>
                                        <img style={{width: '180px', height: '60px'}} src={imgehi} alt=""/>
                                        </div>
                                    </Col>
                                </Row>
                                {/*<div style={{width: '1000px'}}>*/}
                                {/*    <img style={{width: '180px', height: '160px'}} src={imgbaiyun} alt=""/>*/}
                                {/*    <img style={{width: '180px', height: '160px'}} src={imgdongfeng} alt=""/>*/}
                                {/*    <img style={{width: '180px', height: '160px'}} src={imghuaxia} alt=""/>*/}
                                {/*    <img style={{width: '180px', height: '160px'}} src={imghuayu} alt=""/>*/}
                                {/*    <img style={{width: '180px', height: '160px'}} src={imglanjing} alt=""/>*/}
                                {/*    <img style={{width: '180px', height: '160px'}} src={imglianhe} alt=""/>*/}
                                {/*    <img style={{width: '180px', height: '160px'}} src={imgnanwang} alt=""/>*/}
                                {/*    <img style={{width: '180px', height: '160px'}} src={imgsgm} alt=""/>*/}
                                {/*    <img style={{width: '180px', height: '160px'}} src={imgtianan} alt=""/>*/}
                                {/*    <img style={{width: '180px', height: '160px'}} src={imgyiqi} alt=""/>*/}
                                {/*    <img style={{width: '180px', height: '100px'}} src={imgyidong} alt=""/>*/}
                                {/*    <img style={{width: '180px', height: '160px'}} src={imgxinhua} alt=""/>*/}
                                {/*    <img style={{width: '180px', height: '60px'}} src={imgbaojianhui} alt=""/>*/}
                                {/*    <img style={{width: '180px', height: '120px'}} src={imghunan} alt=""/>*/}
                                {/*    <img style={{width: '180px', height: '60px'}} src={imgehi} alt=""/>*/}
                                {/*</div>*/}
                            </Col>
                            {/*<Col span={2}></Col>*/}
                        </Row>
                    </div>
                </div>
            </div>
        )
    }
}
