import React from 'react';
import {Row, Col, Collapse, Card} from 'antd';
import Record from "../common/record"
import sqb from "../../assent/businessLogo/sqb.png";

const {Panel} = Collapse;


const container = {
    padding: '0px 0 40px 0',
    textAlign: 'left',
    height: '500px'
};

const BriefIntroduction = '作品简介 :\n' +
    '网络管理APP是一款基于SNMP协议对网络设备进行监控管理的运维产品，主要包含网络设备基础信息获取，网络设备性能采集、网络设备告警事件管理等方面的功能。'


export default class nopCommunity extends React.Component {
    constructor() {
        super();
    };

    render() {
        return (
            <div style={container}>
                <Record assemblyName='NOP社区版'></Record>
                <Card style={{width: '100%'}}>
                    <Row>
                        <Col span={1}></Col>
                        <Col span={5}>
                            <div style={{
                                padding: '20px',
                                display: 'inline-block',
                                backgroundColor: 'rgb(236, 237, 242)',
                                borderRadius: '15px'
                            }}>
                                <img src={sqb}/>
                            </div>
                        </Col>
                        <Col span={12} style={{lineHeight: '25px', paddingTop: '32px'}}>
                            <span>{BriefIntroduction}</span>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Card>

                <Card style={{width: '100%'}}>
                    <Row>
                        <Col span={6}></Col>
                        <Col span={12}>
                            <div style={{lineHeight: '25px'}}>
                        <span>
                        网络管理APP是一款基于SNMP协议对网络设备进行监控管理的运维产品，主要包含网络设备基础信息获取，网络设备性能采集、网络设备告警事件管理等方面的功能。
                        <br/>
                        <br/>
                        1. 支持基于SNMP协议的网络设备的发现及管控。
                        <br/>
                        2. 支持网络设备的自定义性能采集。
                        <br/>
                        3.支持网络设备的多种类型告警的接收处理，包括性能告警、syslog告警、设备轮询检查等告警。
                        <br/>
                        4.支持网管页面的平台操作审计功能。
                        <br/>
                        网络管理下载渠道：https://bk.tencent.com/s-mart/application/270/detail
                        <br/>
                    </span>
                            </div>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Card>
            </div>
        )
    }
}
