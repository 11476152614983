import React from 'react';
import Record from "../common/record"
import {Row, Col, Collapse } from 'antd';
const { Panel } = Collapse;

const container = {
    padding: '0px 0 40px 0',
    textAlign: 'left'
};

const text = '\t\t\tGeneInfo 是基于大数据和开源架构，基于HTML5展现框架的分布式网络，系统和应用的综合监控管理平台(NMS+APM+CM)，是一个可以进行高度客户化的开放系统。\n'

export default class Company extends React.Component {
    constructor() {
        super();
    };

    render() {
        return (
            <div style={container}>
                <Record assemblyName='公司简介'></Record>
                <div className="section full-width">
                    <div className="container" style={{width: '1170px', margin: 'auto', color: '#666', height: '500px'}}>
                        <Row>
                            <Col span={3}></Col>
                            <Col span={18}>
                                <div>
                                    <p style={{textIndent:'2em'}}>水有源,故其流不穷；木有根，故其生不穷。</p>
                                    <p style={{textIndent:'2em'}}>上海可源信息科技有限公司长期立足于优化企业信息化管理方面的产品与服务解决方案。与HP、微软、BMC等国际厂商建立了良好的合作关系。</p>
                                    <p style={{textIndent:'2em'}}>公司基于开源架构开发的新一代网络管理平台。产品吸收了传统国外软件如Netcool、Open View的长处，改进了性能及用户体验，使产品从企业信息化管理的切实痛点出发，更适合国内大中型企业用户的实际需求。目前已获得多项软件著作权，并获得双软企业认证。经过多年的产品迭代与发展，在国内众多行业积累了典型客户如华夏银行、新华保险、南方电网、上汽通用、天津一汽、东风汽车集团、南方电网等等。</p>
                                    <p style={{textIndent:'2em'}}>公司于2017年9月与腾讯公司签署了《蓝鲸产品代理服务协议》，同时签署了《网络管理产品共同开发品牌合作协议》加入了蓝鲸生态，成为该生态唯一的双料合作伙伴。经过双方近半年的共同努力于2018年5月成功推出了蓝鲸网管平台（将本公司的NOP产品优化移植到蓝鲸平台，成为蓝鲸平台的一个SaaS应用）。公司目前已建立专职团队负责蓝鲸平台从售前到售后及定制开发、咨询服务等多维度服务体系。</p>
                                    <p style={{textIndent:'2em'}}>客户和技术是公司发展之源。公司结合长期积累的客户基础与开源技术的创新，从企业信息化管理的切实痛点出发，专注于新一代企业智能化IT管理，涵盖内容包括ITIL、DevOps、CI/CD、自动化运维等帮助企业高效管理企业IT生命周期。</p>
                                    <p style={{textIndent:'2em'}}>我们的愿景是为中国企业提供更优质、更符合业务需求、更智能、更高性价比的IT管理平台，帮助中国企业在各自的业务领域中取得竞争优势。</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        )
    }
}
